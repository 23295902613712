<template>
    <div>
        <van-nav-bar :title="info.company_name" right-text="二维码" left-text="" left-arrow @click-left="url('/company')"
            @click-right="show_qr = true"></van-nav-bar>
        <van-search placeholder="请输入搜索关键词" v-model="keyword" @input="onSearch"></van-search>

        <div class="inv" @click="url('/invoice?id=' + id)">
            <van-icon name="notes-o" /> 发票申请
        </div>

        <div class="company-count">
            <van-row>
                <van-col span="6" @click="set_state(0)">
                    <div class="count">
                        <div>总办理数</div>
                        <p>{{info.handle_count}}人</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(8)">
                    <div class="count">
                        <div>审核通过</div>
                        <p>{{info.handle_true}}人</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(6)">
                    <div class="count">
                        <div>待审核</div>
                        <p>{{info.handle_other}}人</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(7)">
                    <div class="count">
                        <div>不符合</div>
                        <p>{{info.handle_false}}人</p>
                    </div>
                </van-col>
            </van-row>
        </div>

        <div class="dep">
            <!-- 内部部门 -->
            <van-field label-width="70" label-class="label" v-model="dep_name" readonly clickable
                v-if="dep_list.length" label="内部部门" placeholder="选择部门" @click="dep_show = true" />
            <van-popup v-model:show="dep_show" round position="bottom">
                <van-picker title="内部部门" :columns="dep_list" :show-toolbar="true" @confirm="depConfirm"
                    @cancel="dep_show = false">
                </van-picker>
            </van-popup>
        </div>

        <div class="top">
            <div class="title">
                <div class="title-text">办理列表
                    <span v-if="state==0">(全部列表)</span>
                    <span v-if="state==8">(审核通过)</span>
                    <span v-if="state==6">(待审核)</span>
                    <span v-if="state==7">(不符合)</span>
                    <span style="color: red;z-index: 2000;position: absolute;right: 15px;"
                        @click="company_export">导出</span>
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>

            <div class="company-examine-button">
                <van-button type="primary" size="mini" @click="all">全选</van-button>
                <van-button type="info" size="mini" @click="examine(6)">资料符合</van-button>
                <van-button type="danger" size="mini" @click="examine(7)">资料不符合</van-button>
            </div>

            <van-list v-model="load" :finished="finish" finished-text="没有更多了" @load="get_user_list(state)">
                <van-cell-group class="group-user-list">
                    <van-checkbox-group v-model="select">
                        <div v-for="(item,index) in list" :key="index" class="company-examine">
                            <van-checkbox :name="item.id" :checked-color="config.main_color"
                                class="company-examine-checkbox" v-if="item.state!==8">
                            </van-checkbox>
                            <van-cell class="company-examine-cell" :icon="item.face_img + '!thumbnail'" center is-link
                                @click="url('/order_detail?is_company=1&id=' + item.id)">

                                <template #title>
                                    <div>
                                        <span class="card-name">{{item.name}}</span>
                                        <van-tag color="#ffe1e1" text-color="#ad0000">{{item.cate_name}}</van-tag>
                                    </div>
                                </template>

                                <template #label>
                                    <div class="card-message">
                                        <span><van-tag v-if="item.dep_name" color="#ff0000" text-color="#ffffff">{{item.dep_name}}</van-tag></span>
                                        <span v-if="item.message">激活信息：{{item.message}}</span>
                                    </div>
                                </template>

                                <template #right-icon>
                                    <div>
                                        <van-tag type="primary" v-if="item.state==2 || item.state==6 || item.state==10">
                                            {{item.state_text}}</van-tag>
                                        <van-tag type="success" v-if="item.state==8" :color="config.main_color">
                                            {{item.state_text}}</van-tag>
                                        <van-tag type="danger"
                                            v-if="item.state==4 || item.state==9 || item.state==7 ||item.state==5">
                                            {{item.state_text}}</van-tag>
                                        <van-tag type="warning" v-if="item.state==1">{{item.state_text}}</van-tag>
                                        <van-tag color="#cccccc" v-if="item.state==-1">{{item.state_text}}</van-tag>
                                    </div>
                                </template>
                            </van-cell>
                        </div>
                    </van-checkbox-group>
                </van-cell-group>
            </van-list>

            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>
        </div>

        <!--二维码-->
        <div class="company-qr">
            <van-popup v-model="show_qr">
                <van-image width="300px" height="90vh" fit="cover" :src="info.qr"></van-image>
                <p>{{info.company_name}}</p>
            </van-popup>
        </div>

    </div>
</template>

<script>
    import {
        company_info,
        company_state,
        company_user,
    } from '@/api/company.js';
    import {
        Toast,
        Notify,
        Dialog,
    } from 'vant';
    export default {
        name: 'company_user',
        data() {
            return {
                show_qr: false,
                info: {},
                id: '',
                list: [], //用户列表
                finish: false,
                load: false,
                state: 0, //状态，默认全部
                select: [],
                is_all: false,
                keyword: '',
                dep_id:'',
                dep_name:'',
                dep_show:false,
                dep_list:[],
            }
        },
        mounted() {
            this.get_company();
            this.get_user_list();
        },
        created() {
            this.id = this.$route.query.id;
        },
        methods: {
            //获取企业信息
            get_company() {
                company_info({
                    id: this.$route.query.id
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        var dep_list = [];
                        for(var item of res.data.dep){
                            dep_list.push(item.name);
                        }
                        this.dep_list = dep_list;
                    }
                });
            },

            //全选
            all() {
                var is_all = this.is_all;
                var list = this.list;
                var select = [];
                for (var i in list) {
                    if (list[i].state !== 8) {
                        select[i] = list[i].id;
                    }
                }
                if (!is_all) {
                    this.select = select;
                    this.is_all = true;
                } else {
                    this.select = [];
                    this.is_all = false;
                }
            },

            onSearch(event) {
                this.keyword = event;
                this.list = [];
                this.state = 0;
                this.get_user_list(0);
            },

            
            depConfirm(value, index) {
                this.dep_name = value;
                this.dep_id = this.info.dep[index].id;
                this.dep_show = false;
                this.list = [];
                this.state = 0;
                this.get_user_list(0);
            },


            //企业批量修改状态
            examine(state) {
                //判断是否选择
                if (this.select.length == 0) {
                    Toast('请选择用户!', 'cancel');
                    return false;
                }
                var that = this;
                Dialog.confirm({
                    title: '操作提升',
                    message: '您确定要进行此操作吗？',
                }).then(function (res) {
                    company_state({
                        ids: that.select,
                        state: state,
                    }).then(res => {
                        if (res.code == 1) {
                            that.list = [];
                            that.count = [];
                            that.finish = false;
                            that.select = [];
                            that.get_company();
                            that.get_user_list();
                        }
                    });
                });
            },

            set_state(state) {
                this.list = [];
                this.state = state;
                this.finish = false;
                this.get_user_list(state);
            },

            //获取办理列表
            get_user_list(state) {
                var that = this;
                var data = {
                    state: state, //状态
                    id: that.id,
                    start: that.list.length,
                    keyword: that.keyword,
                    dep_id:this.dep_id,
                    count: 50,
                };
                this.load = true;
                company_user(data).then(res => {
                    if (res.code == 1) {
                        that.load = false;
                        that.list = that.list.concat(res.data.info);
                        if (res.data.count < 50) {
                            that.finish = true; //加载完成
                        }
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },

            company_export() {
                var that = this;
                Dialog.confirm({
                    title: '导出提示',
                    message: '您确定要导出？系统只导出待审核、激活成功、自动激活状态信息。',
                }).then(function (res) {
                    window.location.href = 'https://admin.zykongtong.com/portal/company/company_export?id=' +
                        that.id + '&state=' + that.state + '&dep_id=' + that.dep_id;
                });
            }


        }
    }
</script>

<style scoped>
    .inv {
        padding: 0 15px;
        font-size: 14px;
        font-weight: 600;
        background: var(--main-color);
        color: #fff;
        display: inline-block;
        margin-left: 15px;
        margin-top: 10px;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
    }

    .company-examine {
        position: relative;
        height: 89px;
    }

    .company-examine .van-cell__title {
        flex: 3;
    }


    .company-examine .van-cell__value {
        flex: 1;
    }

    .group-user-list .van-cell__title {
        flex: 3;
    }

    .group-user-list .van-cell__value {
        flex: 1;
    }


    .company-examine-checkbox {
        width: 20px;
        position: absolute;
        left: 10px;
        height: 89px;
        line-height: 89px;
    }

    .company-examine-cell {
        width: calc(100% - 40px);
        position: absolute;
        right: 10px;
    }

    .company-examine-button {
        padding: 5px 10px;
    }

    .company-count {
        text-align: center;
        padding: 15px;
        background: #fff;
        margin: 15px;
        border-radius: 20px;
    }

    .company-count .title {
        text-align: left !important;
        padding-left: 5px !important;
    }


    .company-count .van-col {
        padding: 5px;

    }



    .company-count .count {
        height: 55px;
        border-radius: 5px;
        background: var(--main-color);
    }


    .company-count .count div {
        color: #fff;
        font-size: 12px;
        padding-top: 5px;
    }


    .company-count .count p {
        color: #fff;
        font-size: 16px;
    }

    .group-user-list .van-cell__left-icon {
        min-width: 1.5em;
        height: 45px;
        font-size: 16px;
        line-height: 45px;
    }

    .group-user-list .van-icon__image {
        width: 50px;
        height: 50px;
    }

    .company-qr p {
        text-align: center;
        line-height: 20px;
        font-size: 14px;
        color: #333;
        margin-bottom: 10px;
    }

    .dep {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    /deep/ .van-nav-bar__title {
        color: var(--main-color);
        font-family: 'FZCS';
    }

    /deep/ .van-nav-bar__left i {
        color: var(--main-color) !important;
    }

    /deep/ .van-nav-bar__title,
    /deep/ .van-nav-bar__text {
        color: var(--main-color) !important;
    }
</style>