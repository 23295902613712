import request from '@/utils/request'
export function company_info(data) {
    return request({url: 'company_info',data});
}
export function company_cate(data) {
    return request({url: 'company_cate',data});
}
export function company_submit(data) {
    return request({url: 'company_submit',data});
}
export function company_list(data) {
    return request({url: 'company_list',data});
}
export function company_apply(data) {
    return request({url: 'company_apply',data});
}
export function company_user(data) {
    return request({url: 'company_user',data});
}
export function company_state(data) {
    return request({url: 'company_state',data});
}
export function company_invoice_submit(data) {
    return request({url: 'company_invoice_submit',data});
}